var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.type == 'addEditAddress' ? 'addEditMapAddress' : ''},[_c('div',[_c('label',{staticStyle:{"width":"100%"}},[(_vm.editable)?_c('gmap-autocomplete',{staticClass:"form-control",staticStyle:{"border":"1px solid #a53860","padding":"10px","width":"100%"},attrs:{"placeholder":_vm.$t('Search'),"options":{ fields: ['geometry'] }},on:{"place_changed":_vm.setPlace}}):_vm._e()],1),_c('br')]),_c('br'),_c('GmapMap',{ref:"mymap",staticClass:"mx-auto",attrs:{"center":_vm.center,"zoom":18,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    }}},[_c('gmap-marker',{attrs:{"position":_vm.marker,"clickable":_vm.editable == true ? true : false,"draggable":_vm.editable == true ? true : false},on:{"click":function($event){_vm.center = _vm.marker.position},"dragend":_vm.updateCoordinates}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }