<template>
  <div>
    <v-row class="my-3">
      <v-col xs="6" md="6">
        <p class="main-color main-header">
          {{ $t("Master Data Registration") }}
        </p>
      </v-col>
      <v-col
        xs="6"
        md="6"
        class="edit-icon"
        v-if="!editable"
        :class="currentAppLocale == 'ar' ? 'text-align-left' : ''"
      >
        <v-icon
          class
          v-can="'edit-master-data'"
          @click="editMasterData"
          :title="$t('Edit')"
          large
          >edit</v-icon
        >
        <v-btn icon to="/systemlogging/master_data" target="_blank">
          <v-icon class="main-color" large :title="$t('System Log')"
            >mdi-archive-clock</v-icon
          >
        </v-btn>
      </v-col>
    </v-row>

    <!-- <form class="" @submit.prevent="submitMD()" v-can="'show-master-data'"> -->
    <div class="py-5">
      <v-form v-model="valid" ref="form" v-can="'show-master-data'">
        <v-row>
          <v-col cols="12" class="first-info">
            <v-card class="mx-auto" shaped>
              <v-card-text>
                <v-row class="lighten-5" style justify="center">
                  <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                    <v-row class="py-4">
                      <v-col cols="12">
                        <h3 id="info-header" class="info-header">
                          {{ $t("General Information") }}
                        </h3>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        class="text-center"
                        id="logo-div"
                        v-if="!editable"
                      >
                        <div class="logo-div-img">
                          <img
                            style="width: 80px; height: 80px"
                            v-if="
                              masterData.logo != null && masterData.logo != ''
                            "
                            id="logo"
                            class="rounded-circle"
                            :src="masterData.logo"
                            alt
                          />
                          <img
                            v-else
                            id="logo"
                            class="rounded-circle"
                            src="../../assets/school_logo.png"
                          />
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        class="text-center"
                        id="logo-div"
                        v-if="editable"
                      >
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <img
                              v-if="
                                masterDataEdit.logo != null ||
                                masterDataEdit != ''
                              "
                              :src="masterDataEdit.logo"
                              class="mg-t-30"
                            />

                            <img
                              v-else
                              class="mg-t-30"
                              src="../../assets/school_logo.png"
                            />
                            <div class="custom-file" id="upload-photo">
                              <input
                                type="file"
                                class="custom-file-input"
                                @change="onFileSelected"
                                :rules="[validationRules.required]"
                                title="upload school logo"
                              />
                              <p
                                class="text-center red--text"
                                v-if="imageMaxLength"
                              >
                                {{ imageMaxLength }}
                              </p>
                              <label class="custom-file-label" for="customFile">
                                <br />
                              </label>
                            </div>
                            <img
                              src="../../assets/img/preloader.gif"
                              id="preloader-custom"
                              alt="preloader"
                              class="hidden"
                            />
                            <div v-if="true">
                              <p class="error mg-t-30">{{ logoError }}</p>
                            </div>
                            <div v-if="validation_errors.logo">
                              <p
                                class="red--text"
                                v-for="(error, index) in validation_errors.logo"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="8" id="info-div" class="info-div">
                        <!-- ====================================== show data view==================================== -->
                        <div class="viewData" v-if="!editable">
                          <v-row>
                            <v-col xs="4" md="4">
                              <p>
                                <strong>{{ $t("School Name En") }}: </strong>
                              </p>
                            </v-col>
                            <v-col sm="8" md="8">{{
                              masterData.en.name
                            }}</v-col>
                          </v-row>
                          <v-row>
                            <v-col xs="4" md="4">
                              <p>
                                <strong>{{ $t("School Name Ar") }}:</strong>
                              </p>
                            </v-col>
                            <v-col xs="8" md="8" v-html="masterData.ar.name">
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col xs="4" md="4">
                              <p>
                                <strong
                                  >{{ $t("Authorization Number") }}:</strong
                                >
                              </p>
                            </v-col>
                            <v-col xs="8" class md="8">
                              {{ masterData.authourization_number }}
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col xs="4" md="4">
                              <p>
                                <strong>{{ $t("Tax Number") }}:</strong>
                              </p>
                            </v-col>
                            <v-col xs="8" class md="8">
                              {{ masterData.tax_number }}
                            </v-col>
                          </v-row>
                          <!-- School Id  -->
                          <v-row>
                            <v-col xs="4" md="4">
                              <p>
                                <strong>{{ $t("School ID Number") }}:</strong>
                              </p>
                            </v-col>
                            <v-col xs="8" class md="8">{{
                              masterData.school_id_number
                            }}</v-col>
                          </v-row>
                          <!-- /school Id -->
                          <v-row>
                            <v-col xs="4" md="4">
                              <p>
                                <strong>{{ $t("Native language") }}:</strong>
                              </p>
                            </v-col>
                            <v-col xs="8" class md="8">
                              {{ masterData.master_language }}
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col xs="4" md="4">
                              <p>
                                <strong>{{ $t("Second Language") }}:</strong>
                              </p>
                            </v-col>
                            <v-col xs="8" class md="8">
                              {{ masterData.other_language }}
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col xs="4" md="4">
                              <p>
                                <strong>{{ $t("School Principal") }}:</strong>
                              </p>
                            </v-col>
                            <v-col
                              xs="8"
                              class
                              md="8"
                              v-html="
                                currentAppLocale == 'ar'
                                  ? masterData.ar.school_principal
                                  : masterData.en.school_principal
                              "
                            >
                            </v-col>
                          </v-row>
                        </div>

                        <!-- ========================================== edit data view ======================================= -->
                        <div class="editData" v-if="editable">
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <p class="label">
                                <strong>{{ $t("School Name En") }}:</strong>
                              </p>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                              <v-text-field
                                v-model.trim="masterDataEdit.en.name"
                                solo
                                :rules="[
                                  validationRules.required,
                                  validationRules.maxLength50,
                                ]"
                                @focus="onFocus()"
                              ></v-text-field>
                              <!-- <p
                              class="red--text"
                              v-if="
                                $v.masterDataEdit.en.name.$error &&
                                  !$v.masterDataEdit.en.name.required
                              "
                            >
                              This field is required
                            </p>
                            <p
                              class="red--text"
                              v-if="!$v.masterDataEdit.en.name.maxLength"
                            >
                              You have reached your maximum limit of characters
                              allowed
                            </p>-->
                              <div v-if="validation_errors.en">
                                <p
                                  class="red--text"
                                  v-for="(error, index) in validation_errors.en
                                    .name"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <p class="label">
                                <strong>{{ $t("School Name Ar") }}</strong>
                              </p>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                              <v-text-field
                                v-model.trim="masterDataEdit.ar.name"
                                solo
                                @focus="onFocus()"
                                :rules="[
                                  validationRules.required,
                                  validationRules.maxLength50,
                                  CheckArabicCharactersOnly(
                                    masterDataEdit.ar.name
                                  ),
                                ]"
                              ></v-text-field>
                              <!-- <p
                              class="red--text"
                              v-if="
                                $v.masterDataEdit.ar.name.$error &&
                                  !$v.masterDataEdit.ar.name.required
                              "
                            >
                              This field is required
                            </p>
                            <p
                              class="red--text"
                              v-if="
                                $v.masterDataEdit.ar.name.$error &&
                                  !$v.masterDataEdit.ar.name.maxLength
                              "
                            >
                              You have reached your maximum limit of characters
                              allowed
                            </p>-->
                              <div v-if="validation_errors.ar">
                                <p
                                  class="red--text"
                                  v-for="(error, index) in validation_errors.ar
                                    .name"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <p class="label">
                                <strong
                                  >{{ $t("Authorization Number") }}:</strong
                                >
                              </p>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                              <v-text-field
                                v-model.trim="
                                  masterDataEdit.authourization_number
                                "
                                solo
                                @focus="onFocus()"
                                :rules="[
                                  validationRules.required,
                                  validationRules.maxLength25,
                                ]"
                              ></v-text-field>

                              <div
                                v-if="validation_errors.authourization_number"
                              >
                                <p
                                  class="red--text"
                                  v-for="(
                                    error, index
                                  ) in validation_errors.authourization_number"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <p class="label">
                                <strong>{{ $t("Tax Number") }}:</strong>
                              </p>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                              <v-text-field
                                v-model.trim="masterDataEdit.tax_number"
                                solo
                                @focus="onFocus()"
                                :rules="[
                                  validationRules.required,
                                  validationRules.maxLength25,
                                ]"
                              ></v-text-field>

                              <div v-if="validation_errors.tax_number">
                                <p
                                  class="red--text"
                                  v-for="(
                                    error, index
                                  ) in validation_errors.tax_number"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                          <!-- School Id  -->
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <p class="label">
                                <strong>{{ $t("School ID Number") }} </strong>
                              </p>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                              <v-text-field
                                v-model.trim="masterDataEdit.school_id_number"
                                solo
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <!-- /school Id -->
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <p class="label">
                                <strong>{{ $t("Native Language") }} :</strong>
                              </p>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                              <v-select
                                :items="[
                                  { title: 'choose language', value: '' },
                                  { title: 'English', value: 1 },
                                  { title: 'Arabic', value: 2 },
                                ]"
                                item-text="title"
                                item-value="value"
                                v-model="masterDataEdit.master_language"
                                solo
                                @change="onFocus()"
                                disabled
                                :rules="[validationRules.required]"
                              ></v-select>

                              <!-- <p
                              class="red--text"
                              v-if="
                                $v.masterDataEdit.master_language.$error &&
                                  !$v.masterDataEdit.master_language.required
                              "
                            >
                              This field is required
                            </p>-->
                              <div v-if="validation_errors.master_language">
                                <p
                                  class="red--text"
                                  v-for="(
                                    error, index
                                  ) in validation_errors.master_language"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <p class="label">
                                <strong>{{ $t("Second Language") }}:</strong>
                              </p>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                              <v-select
                                :items="[
                                  { title: 'choose language', value: '' },
                                  { title: 'English', value: 1 },
                                  { title: 'Arabic', value: 2 },
                                ]"
                                item-text="title"
                                item-value="value"
                                v-model="masterDataEdit.other_language"
                                solo
                                @change="onFocus()"
                                :select="onLangSelect()"
                              ></v-select>
                              <div v-if="validation_errors.other_language">
                                <p
                                  class="red--text"
                                  v-for="(
                                    error, index
                                  ) in validation_errors.other_language"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                            </v-col>

                            <!--  -->
                            <v-col cols="12" sm="4" md="4">
                              <p class="label">
                                <strong>{{ $t("School Principal") }}:</strong>
                              </p>
                            </v-col>

                            <v-col cols="6" sm="6" md="4">
                              <v-text-field
                                v-model.trim="
                                  masterDataEdit.en.school_principal
                                "
                                solo
                                :rules="[validationRules.maxLength255]"
                                @focus="onFocus()"
                                placeholder="Name in English"
                              ></v-text-field>

                              <div v-if="validation_errors.en">
                                <p
                                  class="red--text"
                                  v-for="(error, index) in validation_errors.en
                                    .name"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                            </v-col>

                            <v-col cols="6" sm="6" md="4">
                              <v-text-field
                                v-model.trim="
                                  masterDataEdit.ar.school_principal
                                "
                                solo
                                :rules="[
                                  validationRules.maxLength255,
                                  CheckArabicCharactersOnly(
                                    masterDataEdit.ar.school_principal
                                  ),
                                ]"
                                @focus="onFocus()"
                                placeholder="Name in arabic"
                              ></v-text-field>

                              <div v-if="validation_errors.ar">
                                <p
                                  class="red--text"
                                  v-for="(error, index) in validation_errors.en
                                    .name"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                            </v-col>

                            <!--  -->
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="lighten-5" style justify="center">
          <v-col cols="12">
            <v-row class="my-4">
              <v-col class="second-info" cols="12" sm="6">
                <v-card class="mx-auto" shaped>
                  <v-card-text>
                    <v-col cols="12">
                      <h3 id="info-header2" class="info-header">
                        {{ $t("Contact Information") }}
                      </h3>
                    </v-col>
                    <div class="info-div">
                      <!-- ====================================== show data view==================================== -->
                      <div class="viewData" v-if="!editable">
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Address") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class md="8">
                            {{ masterData.address }}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("E-mail") }}:</strong>
                            </p>
                          </v-col>

                          <v-col xs="8" class md="8">
                            {{ masterData.email }}
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Tel") }}.:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class md="8">{{
                            masterData.tel
                          }}</v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Mobile") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class md="8">
                            {{ masterData.mobile }}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("FAX") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class md="8">{{
                            masterData.fax
                          }}</v-col>
                        </v-row>
                      </div>

                      <!-- ========================================== edit data view ======================================= -->
                      <div class="editData" v-if="editable">
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <p class="label">
                              <strong>{{ $t("Address") }}:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model.trim="masterDataEdit.en.address"
                              solo
                              @focus="onFocus()"
                              :rules="[
                                validationRules.required,
                                validationRules.maxLength255,
                              ]"
                            ></v-text-field>
                            <!-- <p
                            class="red--text"
                            v-if="
                              $v.masterDataEdit.en.address.$error &&
                                !$v.masterDataEdit.en.address.required
                            "
                          >
                            This field is required
                          </p>
                          <p
                            class="red--text"
                            v-if="!$v.masterDataEdit.en.address.maxLength"
                          >
                            You have reached your maximum limit of characters
                            allowed
                          </p>-->
                            <div v-if="validation_errors.en">
                              <p
                                class="red--text"
                                v-for="(error, index) in validation_errors.en
                                  .address"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <p class="label">
                              <strong>{{ $t("E-mail") }}:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model.trim="masterDataEdit.email"
                              solo
                              @focus="onFocus()"
                              :rules="[
                                validationRules.required,
                                validationRules.maxLength50,
                                validationRules.email,
                              ]"
                            ></v-text-field>
                            <!-- <p
                            class="red--text"
                            v-if="
                              $v.masterDataEdit.email.$error &&
                                !$v.masterDataEdit.email.required
                            "
                          >
                            This field is required
                          </p>
                          <p
                            class="red--text"
                            v-if="!$v.masterDataEdit.email.email"
                          >
                            Please enter a valid E-mail address
                          </p>-->
                            <!-- <p
                            class="red--text"
                            v-if="!$v.masterDataEdit.email.maxLength"
                          >
                            You have reached your maximum limit of characters
                            allowed
                          </p>-->
                            <div v-if="validation_errors.email">
                              <p
                                class="red--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.email"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <p class="label">
                              <strong>{{ $t("Tel") }}.:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model.trim="masterDataEdit.tel"
                              solo
                              @focus="onFocus()"
                              :rules="[
                                validationRules.required,
                                validationRules.maxLength20,
                                validationRules.numeric,
                              ]"
                            ></v-text-field>
                            <!-- <p
                            class="red--text"
                            v-if="
                              $v.masterDataEdit.tel.$error &&
                                !$v.masterDataEdit.tel.required
                            "
                          >
                            This field is required
                          </p>

                          <p
                            class="red--text"
                            v-if="
                              $v.masterDataEdit.tel.$error &&
                                !$v.masterDataEdit.tel.phoneNum
                            "
                          >
                            Please enter a valid number
                          </p>

                          <p
                            class="red--text"
                            v-if="!$v.masterDataEdit.tel.maxLength"
                          >
                            You have reached your maximum limit of characters
                            allowed
                          </p>-->
                            <div v-if="validation_errors.tel">
                              <p
                                class="red--text"
                                v-for="(error, index) in validation_errors.tel"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <p class="label">
                              <strong>{{ $t("Mobile") }}:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model.trim="masterDataEdit.mobile"
                              solo
                              @focus="onFocus()"
                              :rules="[
                                validationRules.required,
                                validationRules.maxLength20,
                                validationRules.numeric,
                              ]"
                            ></v-text-field>
                            <!-- <p
                            class="red--text"
                            v-if="
                              $v.masterDataEdit.mobile.$error &&
                                !$v.masterDataEdit.mobile.phoneNum
                            "
                          >
                            Please enter a valid number
                          </p>-->
                            <div v-if="validation_errors.mobile">
                              <p
                                class="red--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.mobile"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <p class="label">
                              <strong>{{ $t("FAX") }}:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model.trim="masterDataEdit.fax"
                              solo
                              @focus="onFocus()"
                              :rules="[
                                validationRules.required,
                                validationRules.maxLength20,
                                validationRules.numeric,
                              ]"
                            ></v-text-field>
                            <!-- <p
                            class="red--text"
                            v-if="
                              $v.masterDataEdit.fax.$error &&
                                !$v.masterDataEdit.fax.phoneNum
                            "
                          >
                            Please enter a valid number
                          </p>
                          <p
                            class="red--text"
                            v-if="!$v.masterDataEdit.fax.maxLength"
                          >
                            You have reached your maximum limit of characters
                            allowed
                          </p>-->
                            <div v-if="validation_errors.fax">
                              <p
                                class="red--text"
                                v-for="(error, index) in validation_errors.fax"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="second-info" cols="12" sm="6">
                <v-card class="mx-auto" shaped>
                  <v-card-text>
                    <v-col cols="12">
                      <h3 id="info-header3" class="info-header">
                        {{ $t("Social Information") }}
                      </h3>
                    </v-col>
                    <div class="info-div">
                      <!-- ====================================== show data view==================================== -->
                      <div class="viewData" v-if="!editable">
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Facebook") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class md="8">
                            {{ masterData.facebook_link }}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Twitter") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class md="8">
                            {{ masterData.twitter_link }}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Instagram") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class md="8">
                            {{ masterData.instagram_link }}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Youtube") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class md="8">
                            {{ masterDataEdit.youtube_link }}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Website Link") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class md="8">
                            {{ masterDataEdit.website }}
                          </v-col>
                        </v-row>
                      </div>

                      <!-- ========================================== edit data view ======================================= -->
                      <div class="editData" v-if="editable">
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <p class="label">
                              <strong>{{ $t("Facebook") }}:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model.trim="masterDataEdit.facebook_link"
                              solo
                              @focus="onFocus()"
                              :rules="[validationRules.maxLength255]"
                            ></v-text-field>

                            <!-- <p
                            class="red--text"
                            v-if="!$v.masterDataEdit.facebook_link.maxLength"
                          >
                            You have reached your maximum limit of characters
                            allowed
                          </p>-->
                            <div v-if="validation_errors.facebook_link">
                              <p
                                class="red--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.facebook_link"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <p class="label">
                              <strong>{{ $t("Twitter") }}:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model.trim="masterDataEdit.twitter_link"
                              solo
                              @focus="onFocus()"
                              :rules="[validationRules.maxLength255]"
                            ></v-text-field>

                            <!-- <p
                            class="red--text"
                            v-if="!$v.masterDataEdit.twitter_link.maxLength"
                          >
                            You have reached your maximum limit of characters
                            allowed
                          </p>-->
                            <div v-if="validation_errors.twitter_link">
                              <p
                                class="red--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.twitter_link"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <p class="label">
                              <strong>{{ $t("Instagram") }}:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model.trim="masterDataEdit.instagram_link"
                              solo
                              @focus="onFocus()"
                              :rules="[validationRules.maxLength255]"
                            ></v-text-field>

                            <!-- <p
                            class="red--text"
                            v-if="!$v.masterDataEdit.instagram_link.maxLength"
                          >
                            You have reached your maximum limit of characters
                            allowed
                          </p>-->
                            <div v-if="validation_errors.instagram_link">
                              <p
                                class="red--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.instagram_link"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <p class="label">
                              <strong>{{ $t("Youtube") }}:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model.trim="masterDataEdit.youtube_link"
                              solo
                              @focus="onFocus()"
                              :rules="[validationRules.maxLength255]"
                            ></v-text-field>

                            <!-- <p
                            class="red--text"
                            v-if="!$v.masterDataEdit.youtube_link.maxLength"
                          >
                            You have reached your maximum limit of characters
                            allowed
                          </p>-->

                            <div v-if="validation_errors.youtube_link">
                              <p
                                class="red--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.youtube_link"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <p class="label">
                              <strong>{{ $t("Website Link") }}:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model.trim="masterDataEdit.website"
                              solo
                              @focus="onFocus()"
                              :rules="[validationRules.maxLength255]"
                            ></v-text-field>
                            <!-- <p
                            class="red--text"
                            v-if="!$v.masterDataEdit.website.maxLength"
                          >
                            You have reached your maximum limit of characters
                            allowed
                          </p>-->
                            <div v-if="validation_errors.website">
                              <p
                                class="red--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.email"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="6" class="second-info">
            <SchoolLocation
              v-if="showMapLocation || editable"
              :showMapLocation="showMapLocation"
              :editable="editable"
              :location_lat="Number(masterDataEdit.location_lat)"
              :location_long="Number(masterDataEdit.location_long)"
              @updateLocation="updateLocation"
            ></SchoolLocation>
          </v-col>
        </v-row>
        <v-row v-if="editable">
          <v-col
            cols="12"
            justify="center"
            align="center"
            class="submit-div text-center"
          >
            <v-btn
              id="submit"
              type="submit"
              class="ui-btn modal-btn-cancel"
              large
              @click.prevent="cancel"
            >
              {{ $t("Cancel") }}
            </v-btn>

            <v-btn
              type="submit"
              @click.prevent="submitMD"
              class="ui-btn submit"
              large
            >
              <!-- :loading="loading"
              :disabled="loading" -->
              {{ $t("Save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <!-- </form> -->
  </div>
</template>

<script>
import axios from "axios";
import { MasterDataMixin } from "../../mixins/MasterDataMixin";
import SchoolLocation from "../../components/masterData/schoolLocation.vue";

export default {
  name: "MasterData",
  mixins: [MasterDataMixin],
  data() {
    return {
      valid: false,
      loading: false,

      masterData: {
        authourization_number: "",
        master_language: "",
        other_language: "",
        tel: "",
        fax: "",
        mobile: "",
        school_id_number: "",
        email: "",
        website: "",
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
        youtube_link: "",
        location_lat: "",
        location_long: "",
        logo: "",
        en: {
          name: "",
          address: "",
          school_principal: "",
        },
        ar: {
          name: "",
          school_principal: "",
        },
      },
      masterDataEdit: {
        authourization_number: "",
        master_language: 1,
        other_language: 2,
        school_id_number: "",
        tel: "",
        fax: "",
        mobile: "",
        email: "",
        website: "",
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
        youtube_link: "",
        location_lat: "",
        location_long: "",
        logo: "",
        en: {
          name: "",
          address: "",
          school_principal: "",
        },
        ar: {
          name: "",
          school_principal: "",
        },
      },
      error: false,
      successMsg: "",
      validation_errors: [],
      selectedFile: null,
      logo: "",
      logoError: "",
      otherLangSelected: false,
      submitted: false,
      editable: false,
      imageMaxLength: "",
    };
  },
  methods: {
    updateLocation(lat, lng) {
      this.masterDataEdit.location_lat = lat;
      this.masterDataEdit.location_long = lng;

      // this.masterData.location_lat = lat;
      // this.masterData.location_long = lng;
    },
    editMasterData() {
      this.editable = true;
    },
    submitMD() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.imageMaxLength = "";
        this.getData(true);
        this.$v.$reset();
      }
    },
    onFileSelected(event) {
      this.logoError = "";
      this.imageMaxLength = "";
      document.getElementById("preloader-custom").classList.remove("hidden");
      const fd = new FormData();
      this.selectedFile = event.target.files[0];
      // console.log(this.selectedFile);
      if (this.selectedFile.type != "video/mp4") {
        if (this.selectedFile.size <= 1024 * 1024) {
          fd.append("image", this.selectedFile);
          axios
            .post(this.getApiUrl + "/uploadFormImage", fd, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            })
            .then((response) => {
              if (response.data.status.error) {
                this.logoError =
                  response.data.status.validation_errors.image[0];
              } else {
                this.masterDataEdit.logo = response.data.data.url;
              }
              document
                .getElementById("preloader-custom")
                .classList.add("hidden");
            });
        } else {
          this.imageMaxLength = "Too large image Max length Allowed 1M";
          document.getElementById("preloader-custom").classList.add("hidden");
        }
      } else {
        this.imageMaxLength = "Please enter image";
        document.getElementById("preloader-custom").classList.add("hidden");
      }
    },
    onLangSelect() {
      if (this.masterDataEdit.other_language == 2) {
        this.otherLangSelected = true;
      } else {
        this.otherLangSelected = false;
      }
    },
    onFocus() {
      this.error = false;
      this.validation_errors = [];
    },
  },
  mounted: function () {
    this.getData(false);
  },
  components: { SchoolLocation },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

// should apply on all views
#wrapper {
  transition: all 0.4s ease 0s;
}
.v-application p {
  margin-bottom: 0px !important;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

#wrapper.active {
  // padding-left: 210px;
}
.viewData {
  margin-left: 5px !important;
}
.editData {
  margin-left: 5px !important;
  margin-top: 12px !important;
}
.custom-file {
  width: 70%;
  border: 2px dashed #bbbbbb;
  box-shadow: unset !important;
  margin-left: -2px !important;
  .custom-file-label {
    box-shadow: unset !important;
    border: unset;
    background-color: unset !important;
    &::after {
      display: inline;
      padding: 3px 2;
      background-color: unset !important;
      color: #7297ff;
      margin-right: 36%;
    }
  }
}
.label {
  margin-top: 6%;
}
.logo-div-img {
  margin-top: 15%;
}
// ===============================
</style>
