<template>
  <div>
    <v-card class="mx-auto" shaped>
      <v-card-text>
        <v-row>
          <v-col>
            <h3 id="info-header3" class="info-header py-2">
              {{ $t("School Location") }}
            </h3>

            <div
              class="info-div text-center"
              v-if="showMapLocation || editable"
            >
              <google-map
                ref="myMap"
                :editable="editable"
                v-on:updateLatLng="getLatLng"
                :editCoordinates="{ lat: location_lat, lng: location_long }"
              ></google-map>

              <v-btn
                small
                @click="getCurrentPosition"
                v-if="editable"
                class="ui-btn modal-btn-cancel"
                >{{ $t("Choose The Current Location") }}</v-btn
              >
            </div>
            <div v-else>
              {{ $t("Add School Location") }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import * as VueGoogleMaps from "vue2-google-maps";

import googleMap from "./google-map.vue";
export default {
  components: { googleMap },
  props: ["editable", "location_lat", "location_long", "showMapLocation"],
  name: "schoolLocation",
  data() {
    return {
      editCoordinates: { lat: null, lng: null },
      location: {
        lat: "",
        lng: "",
      },
    };
  },
  computed: {
    // google: VueGoogleMaps.gmapApi,
  },
  methods: {
    getCurrentPosition() {
      this.$refs.myMap.getCurrentPosition();
    },
    getLatLng(lat, lng) {
      this.editCoordinates.lat = lat;
      this.editCoordinates.lng = lng;

      this.$emit(
        "updateLocation",
        this.editCoordinates.lat,
        this.editCoordinates.lng
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";
</style>
